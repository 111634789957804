import React from "react";

function ArrowsTurnToDots({
  width = 41,
  height = 33,
  color = "none",
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.1875 6.24023C29.4688 5.95898 29.9844 5.95898 30.2656 6.24023C30.5469 6.52148 30.5469 7.03711 30.2656 7.31836L27.7812 9.75586H37.25C39.3125 9.75586 41 11.4434 41 13.5059V15.7559C41 16.1777 40.625 16.5059 40.25 16.5059C39.8281 16.5059 39.5 16.1777 39.5 15.7559V13.5059C39.5 12.2871 38.4688 11.2559 37.25 11.2559H27.7812L30.2656 13.7402C30.5469 14.0215 30.5469 14.5371 30.2656 14.8184C29.9844 15.0996 29.4688 15.0996 29.1875 14.8184L25.4375 11.0684C25.1562 10.7871 25.1562 10.2715 25.4375 9.99023L29.1875 6.24023ZM32.5156 21.9902C32.7969 22.2715 32.7969 22.7871 32.5156 23.0684L28.7656 26.8184C28.4844 27.0996 27.9688 27.0996 27.6875 26.8184C27.4062 26.5371 27.4062 26.0215 27.6875 25.7402L30.1719 23.2559H20.75C19.4844 23.2559 18.5 24.2871 18.5 25.5059V27.7559C18.5 28.1777 18.125 28.5059 17.75 28.5059C17.3281 28.5059 17 28.1777 17 27.7559V25.5059C17 23.4434 18.6406 21.7559 20.75 21.7559H30.1719L27.6875 19.3184C27.4062 19.0371 27.4062 18.5215 27.6875 18.2402C27.9688 17.959 28.4844 17.959 28.7656 18.2402L32.5156 21.9902ZM35 22.5059C35 20.8652 36.3125 19.5059 38 19.5059C39.6406 19.5059 41 20.8652 41 22.5059C41 24.1934 39.6406 25.5059 38 25.5059C36.3125 25.5059 35 24.1934 35 22.5059ZM38 24.0059C38.7969 24.0059 39.5 23.3496 39.5 22.5059C39.5 21.709 38.7969 21.0059 38 21.0059C37.1562 21.0059 36.5 21.709 36.5 22.5059C36.5 23.3496 37.1562 24.0059 38 24.0059ZM17 10.5059C17 8.86523 18.3125 7.50586 20 7.50586C21.6406 7.50586 23 8.86523 23 10.5059C23 12.1934 21.6406 13.5059 20 13.5059C18.3125 13.5059 17 12.1934 17 10.5059ZM20 12.0059C20.7969 12.0059 21.5 11.3496 21.5 10.5059C21.5 9.70898 20.7969 9.00586 20 9.00586C19.1562 9.00586 18.5 9.70898 18.5 10.5059C18.5 11.3496 19.1562 12.0059 20 12.0059Z" fill={color} />
      <g opacity="0.1">
        <rect x="0.108314" y="32.3853" width="32.277" height="16.01" transform="rotate(-90 0.108314 32.3853)" fill={color} stroke="#C22C74" strokeWidth="0.216628" />
        <path d="M24.2516 8.36754C28.6732 8.36754 32.2562 11.9263 32.2562 16.3146C32.2562 20.7029 28.6732 24.2616 24.2516 24.2616C19.83 24.2616 16.247 20.7029 16.247 16.3146C16.247 11.9263 19.83 8.36754 24.2516 8.36754Z" fill={color} stroke="#C22C74" strokeWidth="0.216628" />
      </g>
    </svg>
  );
}

export default ArrowsTurnToDots;
