import React from "react";

function Database({
  width = 42,
  height = 33,
  color = "none",
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 42 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.875 8.23047H39.832C39.832 8.1875 39.7891 8.10156 39.5742 7.92969C39.2734 7.67188 38.7148 7.37109 37.9414 7.07031C36.3945 6.51172 34.1602 6.125 31.625 6.125C29.0469 6.125 26.8125 6.51172 25.2656 7.07031C24.4922 7.37109 23.9336 7.67188 23.6328 7.92969C23.418 8.10156 23.375 8.1875 23.375 8.23047V12.6133C23.9766 13.043 24.9648 13.4727 26.2969 13.8164C27.8008 14.1602 29.6484 14.375 31.625 14.375C33.6016 14.375 35.4062 14.1602 36.9102 13.8164C38.2422 13.4727 39.2305 13.043 39.875 12.6133V8.23047ZM39.875 14.2031C39.1016 14.5898 38.1992 14.8906 37.2109 15.1484C35.6211 15.5352 33.6875 15.75 31.625 15.75C29.5195 15.75 27.5859 15.5352 25.9961 15.1484C25.0078 14.8906 24.1055 14.5898 23.375 14.2031V18.1133C23.9766 18.543 24.9648 18.9727 26.2969 19.3164C27.8008 19.6602 29.6484 19.875 31.625 19.875C33.6016 19.875 35.4062 19.6602 36.9102 19.3164C38.2422 18.9727 39.2305 18.543 39.875 18.1133V14.2031ZM23.375 23.3125C23.375 23.3555 23.418 23.4414 23.6328 23.6133C23.9336 23.8711 24.4922 24.1719 25.2656 24.4727C26.8125 25.0312 29.0469 25.375 31.625 25.375C34.1602 25.375 36.3945 25.0312 37.9414 24.4727C38.7148 24.1719 39.2734 23.8711 39.5742 23.6133C39.7891 23.4414 39.832 23.3555 39.875 23.3125V19.7031C39.1016 20.0898 38.1992 20.3906 37.2109 20.6484C35.6211 21.0352 33.6875 21.25 31.625 21.25C29.5195 21.25 27.5859 21.0352 25.9961 20.6484C25.0078 20.3906 24.1055 20.0898 23.375 19.7031V23.3125ZM39.875 23.3125C39.832 23.3125 39.832 23.3125 39.832 23.3125C39.832 23.3125 39.832 23.3125 39.875 23.3125ZM23.332 23.3125H23.375H23.332ZM23.332 8.23047H23.375C23.332 8.23047 23.332 8.23047 23.332 8.23047ZM39.875 8.23047H39.832C39.832 8.23047 39.832 8.23047 39.875 8.23047ZM41.25 23.3125C41.25 25.2461 36.9102 26.75 31.625 26.75C26.2969 26.75 22 25.2461 22 23.3125V8.1875C22 6.29688 26.2969 4.75 31.625 4.75C36.9102 4.75 41.25 6.29688 41.25 8.1875V23.3125Z" fill={color} />
      <circle opacity="0.1" cx="16.2468" cy="16.2468" r="16.1385" fill={color} stroke={color} strokeWidth="0.216628" />
    </svg>
  );
}

export default Database;
