import React from "react";

function BadgeCheck({
  width = 45,
  height = 33,
  color = "none",
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 45 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.5156 19.5625C31.2344 19.8438 30.7188 19.8438 30.4375 19.5625L27.8125 16.9375C27.5312 16.6562 27.5312 16.1406 27.8125 15.8594C28.0938 15.5781 28.6094 15.5781 28.8906 15.8594L31 17.9688L35.6875 13.2344C35.9688 12.9531 36.4844 12.9531 36.7656 13.2344C37.0469 13.5156 37.0469 14.0312 36.7656 14.3125L31.5156 19.5625ZM36.5781 6.15625C38.125 5.875 39.7656 6.34375 40.9844 7.51562C42.1562 8.73438 42.625 10.375 42.3438 11.9219C43.6094 12.8125 44.5 14.3125 44.5 16C44.5 17.7344 43.6094 19.1875 42.3438 20.125C42.625 21.6719 42.1562 23.3125 40.9844 24.4844C39.7656 25.7031 38.125 26.1719 36.5781 25.8906C35.6875 27.1562 34.1875 28 32.5 28C30.7656 28 29.2656 27.1562 28.375 25.8906C26.8281 26.1719 25.1875 25.7031 23.9688 24.4844C22.7969 23.3125 22.3281 21.6719 22.6094 20.125C21.3438 19.1875 20.5 17.7344 20.5 16C20.5 14.3125 21.3438 12.8125 22.6094 11.9219C22.3281 10.375 22.7969 8.73438 23.9688 7.51562C25.1875 6.34375 26.8281 5.875 28.375 6.15625C29.2656 4.89062 30.7656 4 32.4531 4C34.1875 4 35.6875 4.89062 36.5781 6.15625ZM29.125 7.9375L28.5156 7.75C27.3438 7.375 25.9844 7.65625 25.0469 8.59375C24.1094 9.53125 23.8281 10.8906 24.2031 12.0625L24.3906 12.6719L23.8281 12.9531C22.7031 13.5156 22 14.6875 22 16C22 17.3594 22.7031 18.4844 23.8281 19.0938L24.3906 19.375L24.2031 19.9844C23.8281 21.1562 24.1094 22.5156 25.0469 23.4531C25.9844 24.3906 27.3438 24.6719 28.5156 24.2969L29.125 24.1094L29.4062 24.6719C30.0156 25.7969 31.1406 26.5 32.5 26.5C33.8125 26.5 34.9375 25.7969 35.5469 24.6719L35.8281 24.1094L36.4375 24.2969C37.6094 24.6719 38.9688 24.3906 39.9062 23.4531C40.8438 22.5156 41.125 21.1562 40.75 19.9844L40.5625 19.375L41.125 19.0938C42.25 18.4844 43 17.3594 43 16C43 14.6875 42.25 13.5156 41.125 12.9531L40.5625 12.6719L40.75 12.0625C41.125 10.8906 40.8438 9.53125 39.9062 8.59375C38.9688 7.65625 37.6094 7.375 36.4375 7.75L35.8281 7.9375L35.5469 7.375C34.9375 6.25 33.8125 5.5 32.5 5.5C31.1406 5.5 30.0156 6.25 29.4062 7.375L29.125 7.9375Z" fill={color} />
      <g opacity="0.1">
        <path d="M3.62554 32.3744L3.62554 32.3853L0.60847 32.3853L0.608471 0.108313L3.62554 0.108321L3.62554 0.119256L3.72802 0.124787C10.836 0.50844 16.5304 7.56239 16.5304 16.2468C16.5304 24.9313 10.836 31.9852 3.72802 32.3689L3.62554 32.3744Z" fill={color} stroke={color} strokeWidth="0.216628" />
        <path d="M32.5302 32.3698L29.5671 32.3853L29.5671 32.3749L29.4646 32.3694C22.3262 31.9859 16.6083 24.9351 16.6083 16.2556C16.6083 7.57611 22.3263 0.525372 29.4646 0.141878L29.5671 0.13637L29.5671 0.124853L32.5302 0.109374L32.5302 32.3698Z" fill={color} stroke={color} strokeWidth="0.216628" />
      </g>
    </svg>
  );
}

export default BadgeCheck;
